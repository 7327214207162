
import { Box } from '@mui/system'
import React from 'react'

export default function Footer() {
  return (
    <>
    <Box sx={{minHeight:"100px",background:'#000'}}>

    </Box>
    <Box sx={{background:'#000',color:'#fff',textAlign:'center'}}>
      
    </Box>
    </>
  )
}
